<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner" v-if="loadingProgressLogin">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Bem-vindo! 👋🏻
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
            <v-text-field v-model="email" outlined label="E-mail" placeholder="john@example.com" hide-details
              class="mb-3" id="email" name="email" type="email" :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]" data-vv-as="e-mail" persistent-hint>
            </v-text-field>

            <v-text-field v-model="password" outlined :type="isPasswordVisible ? 'text' : 'password'" label="Senha"
              :error-messages="errorMessages.password" placeholder="Password" :append-icon="
                isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
              " :rules="[validators.required]" hide-details="auto" class="mb-2"
              @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <!-- forget link -->
              <router-link :to="{ name: 'auth-forgot-password' }" class="ms-3">
                Esqueceu a senha?
              </router-link>
            </div>

            <v-btn block color="primary" type="submit" class="mt-6" :loading="loadingBtnLogin">
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> Novo na plataforma, registre-se </span>
          <router-link :to="{ name: 'auth-register' }">
            Criar conta
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">ou</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
          <v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1" @click="validAction(link.action)">
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>

        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img class="auth-mask-bg" height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)" />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
    <ProgressBar v-if="loadingProgressbar"></ProgressBar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiLinkedin
} from "@mdi/js";
import { ref, getCurrentInstance, onMounted } from "@vue/composition-api";
import { required, emailValidator } from "@core/utils/validation";
import axios from "axios";
import { useRouter } from "@core/utils";
import themeConfig from "@themeConfig";
import store from "@/store";
import ProgressBar from "@/components/ProgressBar.vue"
import Vue from 'vue'

export default {
  components: {
    ProgressBar
  },
  setup() {

    // Template Ref
    const loginForm = ref(null);

    const vm = getCurrentInstance().proxy;
    const { router } = useRouter();
    const auth_success = ref(false);
    const data_users_success = ref(false);
    const isPasswordVisible = ref(false);
    const loadingBtnLogin = ref(false);
    const email = ref("");
    const password = ref("");
    const loadingProgressbar = ref(false)
    const loadingProgressLogin = ref(true)
    const errorMessages = ref([]);
    const socialLink = [
      {
        icon: mdiLinkedin,
        color: "#1da1f2",
        colorInDark: "#1da1f2",
      },
      {
        icon: mdiGoogle,
        color: "#db4437",
        colorInDark: "#db4437",
      },
    ];

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate();
      if (!isFormValid) return;

      loadingBtnLogin.value = true;
      store.dispatch('snackBarModule/openSnackBar', { color: 'info', timeout: 1200, text: 'Validando e-mail e senha.' });
      store
        .dispatch("auth/login", {
          email: email.value,
          password: password.value,
        })
        .then((response) => {
          if (response.data.data.access_token) {
            const dados = response.data.data
            const token = dados.access_token;
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
            axios.defaults.headers.common['Authorization'] = "Bearer " + token
            localStorage.setItem("accessToken", token);
            localStorage.setItem("vencimento", dados.expires_at);
            const user = response.data.data.user;
            const company = response.data.data.empresa;
            const { ability: userAbility } = user;
            vm.$ability.update(userAbility);
            localStorage.setItem("userAbility", JSON.stringify(userAbility));
            themeConfig.app.name = company.nome
            themeConfig.app.logo = company.logotipo;         
            delete user.ability;
            localStorage.setItem("userData", JSON.stringify(user));
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("company", JSON.stringify(company));
            localStorage.setItem("role", user.role);
            localStorage.setItem("auth_status", "LOGADO");
            store.commit("auth/setUser", user);
            store.commit("auth/setUsuario", user);
            store.commit("auth/auth_status", "LOGADO");
            store.commit("auth/setRole", user.role);
            data_users_success.value = true;
            loadingProgressbar.value = true;
            auth_success.value = false;
            loadingBtnLogin.value = false;
            router.push("/");  
          } else {
            auth_success.value = false;
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Oops, e-mail e senhas informados não encontrados."
            });

            return response;
          }
        })
        .catch((error) => {
          // TODO: Next Update - Show notification
          auth_success.value = false;
          loadingBtnLogin.value = false;
          store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 3000,
            text: "E-mail e senha incorretos"
          });
        })
        .finally(() => {
          loadingBtnLogin.value = false;
        })
    };
    return {
      handleFormSubmit,
      isPasswordVisible,
      loadingBtnLogin,
      email,
      password,
      loadingProgressbar,
      loadingProgressLogin,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/preset/preset/pages/auth.scss";
</style>
